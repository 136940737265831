let selectForm = $('#selectForm')
selectFormEmpty = () => {
  selectForm.val("")
}
$('a.inf2019').click(function(event) {
  selectFormEmpty()
  selectForm.val("Informe 2019")
  return false;
});
$('a.inf2020').click(function(event) {
  selectFormEmpty()
  selectForm.val("Informe 2020")
  return false;
});
$('a.inf2021').click(function(event) {
  selectFormEmpty()
  selectForm.val("Informe 2021")
  return false;
});
$('a.inf2022').click(function(event) {
  selectFormEmpty()
  selectForm.val("Informe 2022")
  return false;
});

let elementsForm,contLogin = 1;

function validationForm(elementsForm,contLogin)
{
  for(let i=0;i<elementsForm.length;i++){
    if ( $.trim($("#"+elementsForm[i]).val()) == "" ) {
      contLogin = 0;
    }
  }
  return contLogin;
}
function validarEmail(valor) {
  if (/^\w+([\.-]?\w+)*@(?:|hotmail|outlook|yahoo|live|gmail|gutand|yopmail)\.(?:|com|es|co)+$/.test(valor)){
    return true;
  } else {
    return false;
  }
}

$('.btnFormSustain').click(function () {
  grecaptcha.ready(function() {
    grecaptcha.execute('6LfRdtwZAAAAAGAsZTr769WArVkDxPg3hV2MFeU7', {action: 'submit'}).then(function(token) {
      $('#formSustain').prepend('<input type="hidden" name="token" value="' + token + '">');
      $('#formSustain').submit();
    });
  });
  //$('#formSustain').submit();
});

$('#formSustain').on('submit',function (e) {
  e.preventDefault();
  elementsForm = [
    "nameForm",
    "companyForm",
    "mailForm",
    "selectForm"
  ];

  contLogin = validationForm(elementsForm,contLogin = 1);
  if(contLogin == 0)
  {
    swal({
      text: "Debes digitar todos los datos requeridos",
      icon: imageURL,
      button: "OK",
    });
    return false;
  }
  if(!validarEmail($('#mailForm').val())){
    swal({
      text: "Debes digitar un correo valido",
      icon: imageURL,
      button: "OK!",
    });
    $('#mailForm').addClass('error');
    return false;
  }
  let data = $(this).serialize();
  swal({
    text: "Espere un momento",
    icon: imageURL,
    button: false,
    closeOnClickOutside: false,
  });
  $.ajax({
    url: https + "sustain",
    method: "POST",
    data: data,
    success: function (data) {
      if ($.trim(data) == "1") {
        $("#formSustain")[0].reset();
        swal({
          text: "El mensaje ha sido enviado",
          icon: imageURL,
          timer: 3000,
          button: "OK",
        });
      }else {
        swal({
          text: "Error, hubo problemas",
          icon: imageURL,
          timer: 3000,
          button: "OK!",
        });
      }
    }
  });
});
