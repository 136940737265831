'use strict';

var _ = require('lodash');
window.$ = window.jQuery = require('jquery');
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

require('materialize-css');
require('slick-carousel');
require('sweetalert');
//require('jquery-modal')
require('./home');
require('./etic');
require('./galery');
require('./search');
require('./contact');
require('./contactBlog');
require('./contactSustain');
require('./ethics');
require('./cylinder');
require('./pqrs');
require('./accessSost');

let lazyImages = [...document.querySelectorAll('.lazy')];

function lazyLoad() {
    lazyImages.forEach(image => {
        if(elementVisible(image)){
            image.src = image.dataset.src;
            image.onload = () => image.classList.add('loaded');
        }
    });
}

window.addEventListener('scroll', _.throttle(lazyLoad, 16));
window.addEventListener('resize', _.throttle(lazyLoad, 16));

$(document).ready(function(){
    function cargar(){
        setTimeout(function () {
            $('#loading').css('display','none');
            $('#content').css('visibility','initial');
            $('body').css('overflow-x','visible');
            $('body').css('overflow-y','visible');
            lazyLoad();
            setTimeout(function () {
                //getInstagram();
            },100);
        },0);
    }
    window.load = cargar();
    $('.modal').modal({
        dismissible: false,
    });
    $('#mobile_menu').sidenav({
        edge: 'left',
    });
    $('#list_search').sidenav({
        edge: 'left',
    });
});

$('.clickAircraft').click(function () {
    $('html, body').animate({
        scrollTop: $('#aircraft').offset().top - 100
    }, 2000);
});
$('.close-menu').click(function () {
    $('.sidenav').sidenav('close');
});

$('.listNews').click(function () {
    $('#list_search').sidenav('open');
});
function elementVisible(element){
    var visible = true;
    var windowTop = $(document).scrollTop();
    var windowBottom = windowTop + window.innerHeight;
    var elementPositionTop =  $(element).offset().top;
    var elementPositionBottom = elementPositionTop + $(element).height();
    if (elementPositionTop >= windowBottom || elementPositionBottom <= windowTop) {
        visible = false;
    }
    return visible;
}

$(window).scroll(function() {
    if ($(window).width() > 600) {
        if ($(document).scrollTop() > 10) {
            $('.sectionHeader').addClass('header-shadown');
        } else {
            if ($(document).scrollTop() >= 0 && $(document).scrollTop() <= 85) {
                $('.sectionHeader').removeClass('header-shadown');
            }
        }
    }
});
var instagram_count = 1;
$(window).scroll(function() {
    if(elementVisible( $('.sectionFooter') )){
        if(instagram_count == 1){
            instagram_count = 2;
            getInstagram();
        }
    }
});

$('.openModalSustain').click(function () {
    $('#backDropModalSust').addClass('scale-up-center')
    $('#modalSustain').addClass('visible')
});
$('#closeModalSust').click(function () {
    $('#modalSustain').removeClass('visible')
    $('#backDropModalSust').removeClass('scale-up-center')
});


$('.modal').click(function (e) {
    if($(e.target).parents('.rowOne').length == 0){
        $('.modal').modal('close');
    }
});

$('.openModalSector').click(function () {
    //$('.modalSectors').modal('open');
    $('html, body').animate({
        scrollTop: $('.sectionSectorsHome').offset().top - 200
    }, 2000);
});

$('.openModalContact').click(function () {
    $('.modalContact').modal('open');
});

$('.openModalPqrs').click(function () {
    $('.modalPqrs').modal('open');
});

$('.openModalCylinders').click(function () {
    $('.modalCylinders').modal('open');
});

$('.openAccess').click(function () {
    $('.modalAccess').modal('open');
});

//get instagram
function getInstagram() {
    //return false;
    var token_instagram = api_instagram;
    var URL_API_INSTAGRAM = "https://graph.instagram.com/me?fields=username,media&access_token="+token_instagram;
    $.getJSON(URL_API_INSTAGRAM, function (data) {
        var i = 0;
        var url_instagram = '';
        $('.instragramImages').html("");
        $.each(data.media.data, function (item, value) {
            if (i <= 6) {
                var MediaInstagram = "https://graph.instagram.com/"+value.id+"?fields=caption,media_url,media_type,id,permalink,thumbnail_url&access_token="+token_instagram;
                $.getJSON(MediaInstagram, function (data_media) {
                    if(data_media.media_type == 'VIDEO'){
                        url_instagram = data_media.thumbnail_url;
                    }else{
                        url_instagram = data_media.media_url;
                    }
                    $('.instragramImages').append('' +
                        '\n' +
                        '                            <div>\n' +
                        '                                <div class="inst">\n' +
                        '                                    <a target="_blank" href="'+data_media.permalink+'">\n' +
                        '                                        <div class="containerInstagram">\n' +
                        '                                            <div class="img">\n' +
                        '                                                <img src="'+url_instagram+'" alt="">\n' +
                        '                                            </div>\n' +
                        '                                        </div>\n' +
                        '                                    </a>\n' +
                        '                                </div>\n' +
                        '                            </div>');
                });
                if (i == 6) {
                    setTimeout(function () {
                        $('#sliderInstagram').slick({
                            dots: false,
                            infinite: true,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            adaptiveHeight: false,
                            arrows: true,
                            autoplay: false,
                            autoplaySpeed: 4000,
                            swipe: true,
                            pauseOnHover: false,
                            pauseOnDotsHover: false,
                            pauseOnFocus: false,
                            prevArrow: $('.sectionFooter .arrowLeft'),
                            nextArrow: $('.sectionFooter .arrowRight'),
                            lazyLoad: 'ondemand',
                            responsive: [
                                {
                                    breakpoint: 600,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1
                                    }
                                },
                            ]
                        });
                    },1000)
                }
            }
            i++;
        });
    });
}

$('.sectionInfoManufacture .video').click(function () {
   $('.video').css('display','none');
   $('video').css('display','block');
   setTimeout(function () {
       $('video').trigger('play');
   },1000);
});

$('.changeWeb').click(function () {
    var url_web = window.location.href;
    url_web = url_web.replace('https://hscol.com.co/','https://en.hscol.com.co/');
    location.href = url_web;
});

$('.changeWeb2').click(function () {
    var url_web = window.location.href;
    url_web = url_web.replace('https://hscol.com.co/','https://hscol.com.co/');
    location.href = url_web;
});
