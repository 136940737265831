var elementsForm,contLogin = 1;

function validationForm(elementsForm,contLogin)
{
  for(var i=0;i<elementsForm.length;i++){
    $('#'+elementsForm[i]).removeClass('error');
    if ( $.trim($("#"+elementsForm[i]).val()) == "" ) {
      contLogin = 0;
      $('#'+elementsForm[i]).addClass('error');
    }
  }
  return contLogin;
}

$('.btnFormBlog').click(function () {
  //$('#formBlog').submit();
  grecaptcha.ready(function() {
    grecaptcha.execute('6LfRdtwZAAAAAGAsZTr769WArVkDxPg3hV2MFeU7', {action: 'submit'}).then(function(token) {
      $('#formBlog').prepend('<input type="hidden" name="token" value="' + token + '">');
      $('#formBlog').submit();
    });
  });
});

$('#formBlog').on('submit',function (e) {
  e.preventDefault();
  elementsForm = [
    "nameBlog",
    "phoneBlog",
    "companyBlog",
    "mailBlog",
    "messageBlog",
  ];
  contLogin = validationForm(elementsForm,contLogin = 1);
  if(contLogin == 0)
  {
    swal({
      text: "Debes digitar todos los datos requeridos",
      icon: imageURL,
      button: "OK!",
    });
    return false;
  }
  if(!validarEmail($('#mailBlog').val())){
    swal({
      text: "Debes digitar un correo correcto",
      icon: imageURL,
      button: "OK!",
    });
    $('#mailBlog').addClass('error');
    return false;
  }
  var data = $(this).serialize();
  swal({
    text: "Espere un momento",
    icon: imageURL,
    button: false,
    closeOnClickOutside: false,
  });
  $.ajax({
    url: https + "contactBlog",
    method: "POST",
    data: data,
    success: function (data) {
      if ($.trim(data) == "1") {
        $("#formBlog")[0].reset();
        swal({
          text: "Muchas Gracias, pronto nos pondremos en contacto con usted",
          icon: imageURL,
          button: "OK!",
        });
        swal.close();
      }else{
        swal({
          text: "Error, hubo problemas",
          icon: imageURL,
          button: "OK!",
        });
      }
    }
  });
});

function validarEmail(valor) {
  if (/^\w+([\.-]?\w+)*@(?:|hotmail|outlook|yahoo|live|gmail|gutand|yopmail)\.(?:|com|es)+$/.test(valor)){
    return true;
  } else {
    return false;
  }
}
