var elementsForm,contLogin = 1;

function validationForm(elementsForm,contLogin)
{
    for(var i=0;i<elementsForm.length;i++){
        $('#'+elementsForm[i]).removeClass('error');
        if ( $.trim($("#"+elementsForm[i]).val()) == "" ) {
            contLogin = 0;
            $('#'+elementsForm[i]).addClass('error');
        }
    }
    return contLogin;
}

$('.btnFormEthics').click(function () {
    grecaptcha.ready(function() {
        grecaptcha.execute('6LfRdtwZAAAAAGAsZTr769WArVkDxPg3hV2MFeU7', {action: 'submit'}).then(function(token) {
            $('#formEthics').prepend('<input type="hidden" name="token" id="token" value="' + token + '">');
            $('#formEthics').submit();
        });
    });
    //$('#formEthics').submit();
});

$('#formEthics').on('submit',function (e) {
    e.preventDefault();
    elementsForm = [
        "detail_report",
        "denuncia",
        //"other",
    ];
    contLogin = validationForm(elementsForm,contLogin = 1);
    if(contLogin == 0)
    {
        swal({
            text: "Debes digitar todos los datos requeridos",
            icon: imageURL,
            button: "OK!",
        });
        return false;
    }
    var data = $(this).serialize();
    var formData = new FormData();
    formData.append('file', $('#formEthics #file')[0].files[0]);
    formData.append('detail_report', $('#formEthics #detail_report').val());
    formData.append('denuncia', $('#formEthics #denuncia').val());
    formData.append('other', $('#formEthics #other').val());
    formData.append('type', $("input[name='type']:checked").val());
    formData.append('token', $('#formEthics #token').val());
    swal({
        text: "Espere un momento",
        icon: imageURL,
        button: false,
        closeOnClickOutside: false,
    });
    $.ajax({
        url: https + "contactEthics",
        method: "POST",
        data: formData,
        processData: false,
        contentType: false,
        success: function (data) {
            if ($.trim(data) == "1") {
                $("#formEthics")[0].reset();
                swal({
                    text: "Muchas Gracias, pronto nos pondremos en contacto con usted",
                    icon: imageURL,
                    button: "OK!",
                });
                swal.close();
            }else{
                swal({
                    text: "Error, hubo problemas",
                    icon: imageURL,
                    button: "OK!",
                });
            }
        }
    });
});
