var elementsForm,contLogin = 1;

function validationForm(elementsForm,contLogin)
{
    for(var i=0;i<elementsForm.length;i++){
        $('#'+elementsForm[i]).removeClass('error');
        if ( $.trim($("#"+elementsForm[i]).val()) == "" ) {
            contLogin = 0;
            $('#'+elementsForm[i]).addClass('error');
        }
    }
    return contLogin;
}

$('.btnFormCylinders').click(function () {
    //$('#formCylinders').submit();
    grecaptcha.ready(function() {
        grecaptcha.execute('6LfRdtwZAAAAAGAsZTr769WArVkDxPg3hV2MFeU7', {action: 'submit'}).then(function(token) {
            $('#formCylinders').prepend('<input type="hidden" name="token" value="' + token + '">');
            $('#formCylinders').submit();
        });
    });
});

$('#formCylinders').on('submit',function (e) {
    e.preventDefault();
    elementsForm = [
        "or",
        "client",
        "type",
        "observation",
        "observation_1",
        "description_application",
        "critical_zone",
        "text_here_1",
        "text_here_2",
        "camisa",
        "vastago",
        "description_fail",
        "observation_info",
        "observation_aditionals",
    ];
    contLogin = validationForm(elementsForm,contLogin = 1);
    if(contLogin == 0)
    {
        swal({
            text: "Debes digitar todos los datos requeridos",
            icon: imageURL,
            button: "OK!",
        });
        return false;
    }
    var data = $(this).serialize();
    swal({
        text: "Espere un momento",
        icon: imageURL,
        button: false,
        closeOnClickOutside: false,
    });
    $.ajax({
        url: https + "contactCylinder",
        method: "POST",
        data: data,
        success: function (data) {
            if ($.trim(data) == "1") {
                $("#formCylinders")[0].reset();
                swal({
                    text: "Muchas Gracias, pronto nos pondremos en contacto con usted",
                    icon: imageURL,
                    button: "OK!",
                });
                swal.close();
            }else{
                swal({
                    text: "Error, hubo problemas",
                    icon: imageURL,
                    button: "OK!",
                });
            }
        }
    });
});
