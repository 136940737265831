$(document).ready(function () {
    if(window.location.href.replace(https,'') == '' || window.location.href.replace(https,'').includes('?')){
        $('#slickBanner').slick({
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            autoplay: true,
            autoplaySpeed: 4000,
            cssEase: 'easeOutElastic',
            fade: true,
            swipe: true,
            pauseOnHover: false,
            pauseOnDotsHover: false,
            pauseOnFocus: false,
            arrows: true,
            prevArrow: $('.backB'),
            nextArrow: $('.nextB'),
        });
        $('#sliderGalery').slick({
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: true,
            arrows: true,
            autoplay: false,
            autoplaySpeed: 4000,
            cssEase: 'easeOutElastic',
            pauseOnHover: false,
            pauseOnDotsHover: false,
            pauseOnFocus: false,
            prevArrow: $('.sectionGaleryHome .arrowLeft'),
            nextArrow: $('.sectionGaleryHome .arrowRight'),
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
            ]
        });
    }
});
