var elementsForm,contLogin = 1;

function validationForm(elementsForm,contLogin)
{
    for(var i=0;i<elementsForm.length;i++){
        $('#'+elementsForm[i]).removeClass('error');
        if ( $.trim($("#"+elementsForm[i]).val()) == "" ) {
            contLogin = 0;
            $('#'+elementsForm[i]).addClass('error');
        }
    }
    return contLogin;
}

$('.btnFormAccessSost').click(function () {
    $('#formAccessSost').submit();
});

$('#formAccessSost').on('submit',function (e) {
    e.preventDefault();
    elementsForm = [
        "user_accessSost",
        "password_accessSost"
    ];
    contLogin = validationForm(elementsForm,contLogin = 1);
    if(contLogin == 0)
    {
        swal({
            text: "Debes digitar todos los datos requeridos",
            icon: imageURL,
            button: "OK!",
        });
        return false;
    }

    var data = $(this).serialize();
    swal({
        text: "Espere un momento",
        icon: imageURL,
        button: false,
        closeOnClickOutside: false,
    });
    
    $.ajax({
        url: https + "accessSostGo",
        method: "POST",
        data: data,
        success: function (data) {
            console.log(data)
            if ($.trim(data) == "1") {
                $("#formAccessSost")[0].reset();
                location.href = '/archivo-sostenibilidad';
            }else if ($.trim(data) == "2"){
                swal({
                    text: "Datos de accesos incorrecto",
                    icon: imageURL,
                    button: "OK!",
                });
            }else{
                swal({
                    text: "Error, hubo problemas",
                    icon: imageURL,
                    button: "OK!",
                });
            }
        }
    });
});
