var elementsForm,contLogin = 1;

function validationForm(elementsForm,contLogin)
{
    for(var i=0;i<elementsForm.length;i++){
        $('#'+elementsForm[i]).removeClass('error');
        if ( $.trim($("#"+elementsForm[i]).val()) == "" ) {
            contLogin = 0;
            $('#'+elementsForm[i]).addClass('error');
        }
    }
    return contLogin;
}

$('.btnFormPqrs').click(function () {
    //$('#formPqrs').submit();
    grecaptcha.ready(function() {
        grecaptcha.execute('6LfRdtwZAAAAAGAsZTr769WArVkDxPg3hV2MFeU7', {action: 'submit'}).then(function(token) {
            $('#formPqrs').prepend('<input type="hidden" name="token" id="tokenPqrs" value="' + token + '">');
            $('#formPqrs').submit();
        });
    });
});

$('#formPqrs').on('submit',function (e) {
    e.preventDefault();
    elementsForm = [
        "categoryPqrs",
        "messagePqrs",
        //"requestPqrs",
        "clientPqrs",
        "contactPqrs",
        "phonePqrs",
        "emailPqrs",
    ];
    contLogin = validationForm(elementsForm,contLogin = 1);
    if(contLogin == 0)
    {
        swal({
            text: "Debes digitar todos los datos requeridos",
            icon: imageURL,
            button: "OK!",
        });
        return false;
    }
    if(!validarEmail($('#emailPqrs').val())){
        swal({
            text: "Debes digitar un correo correcto",
            icon: imageURL,
            button: "OK!",
        });
        $('#emailPqrs').addClass('error');
        return false;
    }
    var data = $(this).serialize();
    var formData = new FormData();
    formData.append('file', $('#formPqrs #filePqrs')[0].files[0]);
    formData.append('category', $('#formPqrs #categoryPqrs').val());
    formData.append('message', $('#formPqrs #messagePqrs').val());
    formData.append('request_p', $('#formPqrs #requestPqrs').val());
    formData.append('client', $('#formPqrs #clientPqrs').val());
    formData.append('contact', $('#formPqrs #contactPqrs').val());
    formData.append('phone', $('#formPqrs #phonePqrs').val());
    formData.append('mail', $('#formPqrs #emailPqrs').val());
    formData.append('token', $('#formPqrs #tokenPqrs').val());
    swal({
        text: "Espere un momento",
        icon: imageURL,
        button: false,
        closeOnClickOutside: false,
    });
    $.ajax({
        url: https + "pqrsGo",
        method: "POST",
        data: formData,
        processData: false,
        contentType: false,
        success: function (data) {
            if ($.trim(data) == "1") {
                $("#formPqrs")[0].reset();
                swal({
                    text: "Muchas Gracias, pronto nos pondremos en contacto con usted",
                    icon: imageURL,
                    button: "OK!",
                });
                swal.close();
            }else{
                swal({
                    text: "Error, hubo problemas",
                    icon: imageURL,
                    button: "OK!",
                });
            }
        }
    });
});

function validarEmail(valor) {
    if (/^\w+([\.-]?\w+)*@(?:|hotmail|outlook|yahoo|live|gmail)\.(?:|com|es)+$/.test(valor)){
        return true;
    } else {
        return false;
    }
}
