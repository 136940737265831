$('#list_search input').keyup(function () {
    setTimeout(function () {
        $('#formSearch').submit();
    },10);
});

$('#formSearch').on('submit',function (e) {
    e.preventDefault();
    var data = $(this).serialize();
    $.ajax({
        url: https + "search",
        method: "POST",
        data: data,
        success: function (data) {
            $('#list_search .div').html();
            $('#list_search .div').html(data);
        }
    });
});
